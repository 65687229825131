import jquery from "jquery";
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import "@popperjs/core";
import "bootstrap";

/* import "slick-carousel"; */
import "./slick-test.js";
import hcSticky from "hc-sticky";
import AfterViewportJs from "@lysla/afterviewportjs";
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";
import "jquery-match-height";
import simpleParallax from "simple-parallax-js";

//#region offcavas menu mobile e filters
$("[data-trigger]").on("click", function () {
  var trigger_id = $(this).attr("data-trigger");
  $(trigger_id).toggleClass("show");
  $("body").toggleClass("offcanvas-active");
});
$(".close-icon").on("click", function (e) {
  $("#navbarMain").removeClass("show");
  $("body").removeClass("offcanvas-active");
});

//#endregion

//#region stickybar
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".stickybar", {
    stickTo: "body",
    stickyClass: "stickybar--sticked",
  });
});
//#endregion

//#region slider
$(function () {
  $(".slider-scritte").slick({
    dots: false,
    arrows: false,
    speed: 3000,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    slidesToShow: 3,
    swipe: false,
    swipeToSlide: false,
    pauseOnHover: false,
  });
  $(".slider-academy").slick({
    arrows: true,
    centerMode: true,
    variableWidth: true,
    dots: false,
    slidesToShow: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 800,
    swipe: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: false,
          infinite: true,
          centerMode: false,
          variableWidth: false,
          dots: false,
        },
      },
    ],
  });
  $(".slider-trusted").slick({
    infinite: true,
    slidesToShow: 5,
    dots: false,
    arrows: false,
    speed: 600,
    autoplay: true,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    swipe: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
    ],
  });
  $(".slider-lightbox").slick({
    /*  dots: false,
    arrows: false,
    speed: 3000,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    slidesToShow: 4,
    swipe: true,
    swipeToSlide: true,
    pauseOnHover: true, */
    dots: false,
    arrows: false,
    speed: 800,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    slidesToShow: 4,
    slidesToScroll: 1,
    swipe: true,
    swipeToSlide: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
    ],
  });
  $(".slider-team").slick({
    /* dots: false,
    arrows: false,
    speed: 3000,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    slidesToShow: 4,
    swipe: true,
    swipeToSlide: true,
    pauseOnHover: true, */
    dots: false,
    arrows: false,
    speed: 800,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    slidesToShow: 4,
    slidesToScroll: 1,
    swipe: true,
    swipeToSlide: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
          autoplay: false,
          autoplaySpeed: 0,
          speed: 400,
        },
      },
    ],
  });
  $(".slider-reviews").slick({
    infinite: false,
    slidesToShow: 1,
    dots: true,
    arrows: false,
    speed: 600,
    autoplay: true,
    autoplaySpeed: 4000,
    fade: true,
    cssEase: "linear",
    swipe: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false,
        },
      },
    ],
  });
  $(".slider-split").slick({
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    speed: 2000,
    fade: true,
    arrows: false,
    swipe: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false,
          swipe: true,
          swipeToSlide: true,
          touchThreshold: 100,
          infinite: true,
        },
      },
    ],
  });
  $(".link-slider").slick({
    arrows: false,
    centerMode: true,
    variableWidth: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 6000,
    swipe: true,
    swipeToSlide: true,
    touchThreshold: 100,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false,
        },
      },
    ],
  });
  $(".slider-icon-link").slick({
    infinite: false,
    slidesToShow: 4,
    dots: true,
    arrows: false,
    speed: 600,
    autoplay: true,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
});
//#endregion
//#region gallery
Fancybox.bind('[data-fancybox="gallery__lightgallery"]', {
  // Your custom options
});
//#endregion
//#region match height
$(function () {
  $('[data-mh="service-title"]').matchHeight({
    byRow: false,
  });
});
//#endregion
//#region parallax
var video = document.getElementsByClassName("page-header__video-container");
new simpleParallax(video, {
  delay: 0,
  transition: "cubic-bezier(0,0,0,1)",
  scale: 2,
});
//#endregion

//#region scroll to lista academy

// now whenever you are ready do whatever you want
// (in this case I use jQuery to scroll to the tag after the page has loaded)
$(window).on("load", function () {
  if (window.location.hash == "#newsbox" || window.location.href.indexOf("category") > 0 || window.location.href.indexOf("pagenumber") > 0) {
    $("html, body").animate(
      {
        scrollTop: $("#" + "news-box").offset().top,
      },
      700,
      "swing",
      function () {}
    );
  }
});
//#endregion
// validation
//(() => {
//  "use strict";

//  // Fetch all the forms we want to apply custom Bootstrap validation styles to
//  const forms = document.querySelectorAll(".needs-validation");

//  // Loop over them and prevent submission
//  Array.from(forms).forEach((form) => {
//    form.addEventListener(
//      "submit",
//      (event) => {
//        if (!form.checkValidity()) {
//          event.preventDefault();
//          event.stopPropagation();
//        }

//        form.classList.add("was-validated");
//      },
//      false
//    );
//  });
//})();

/*
//#region form
const formContatti = document.getElementById("formContatti");

if (formContatti != null) {
  console.log("init form");
}
document.getElementById("confirmBtn").onclick = function () {
  console.log("funzione!!");

  if (checkCampi()) {
    formContatti.submit();
  }
};

document.getElementById("nome").onchange = function () {
  checkCampi();
};
document.getElementById("cognome").onchange = function () {
  checkCampi();
};
document.getElementById("email").onchange = function () {
  checkCampi();
};
document.getElementById("company").onchange = function () {
  checkCampi();
};
document.getElementById("subject").onchange = function () {
  checkCampi();
};
document.getElementById("testoLibero").onchange = function () {
  checkCampi();
};
document.getElementById("privacy").onchange = function () {
  checkCampi();
};
document.getElementById("privacy2").onchange = function () {
  checkCampi();
};

function checkCampi() {
  const labelAlert = document.getElementById("alert-message");
  if (document.getElementById("nome").value == null || document.getElementById("nome").value == "") {
    labelAlert.style.display = "inline";
    return false;
  }
  else if (document.getElementById("cognome").value == null || document.getElementById("cognome").value == "") {
    labelAlert.style.display = "inline";
    return false;
  }
  else if (document.getElementById("email").value == null || document.getElementById("email").value == "") {
    labelAlert.style.display = "inline";
    return false;
  }
  else if (document.getElementById("subject").value == null || document.getElementById("subject").value == "") {
    labelAlert.style.display = "inline";
    return false;
  }
  else if (document.getElementById("company").value == null || document.getElementById("company").value == "") {
    labelAlert.style.display = "inline";
    return false;
  }
  else if (document.getElementById("testoLibero").value == null || document.getElementById("testoLibero").value == "") {
    labelAlert.style.display = "inline";
    return false;
  }
  else if (document.getElementById("privacy") == null || !document.getElementById("privacy").checked) {
    labelAlert.style.display = "inline";
    return false;
  }
  else if (document.getElementById("privacy2") == null || !document.getElementById("privacy2").checked) {
    labelAlert.style.display = "inline";
    return false;
  }

  labelAlert.style.display = "none";

  return true;
}

window.checkCampi = checkCampi;
//#endregion

//#region form land
const formContattiLand = document.getElementById("formContattiLand");

if (formContattiLand != null) {
  console.log("init form");
}

document.getElementById("confirmBtnLand").onclick = function () {
  console.log("funzione!!");

  if (checkCampiLand()) {
    formContattiLand.submit();
  }
};
document.getElementById("nomeLand").onchange = function () {
  checkCampiLand();
};
document.getElementById("cognomeLand").onchange = function () {
  checkCampiLand();
};
document.getElementById("emailLand").onchange = function () {
  checkCampiLand();
};
document.getElementById("phoneLand").onchange = function () {
  checkCampiLand();
};
document.getElementById("companyLand").onchange = function () {
  checkCampiLand();
};
document.getElementById("companyroleLand").onchange = function () {
  checkCampiLand();
};
document.getElementById("privacyLand").onchange = function () {
  checkCampiLand();
};
document.getElementById("privacyLand2").onchange = function () {
  checkCampiLand();
};

function checkCampiLand() {
  const labelAlertLand = document.getElementById("alert-message-land");
  if (document.getElementById("nomeLand").value == null || document.getElementById("nomeLand").value == "") {
    labelAlertLand.style.display = "inline";
    return false;
  }
  else if (document.getElementById("cognomeLand").value == null || document.getElementById("cognomeLand").value == "") {
    labelAlertLand.style.display = "inline";
    return false;
  }
  else if (document.getElementById("emailLand").value == null || document.getElementById("emailLand").value == "") {
    labelAlertLand.style.display = "inline";
    return false;
  }
  else if (document.getElementById("phoneLand").value == null || document.getElementById("phoneLand").value == "") {
    labelAlertLand.style.display = "inline";
    return false;
  }
  else if (document.getElementById("companyLand").value == null || document.getElementById("companyLand").value == "") {
    labelAlertLand.style.display = "inline";
    return false;
  }
  else if (document.getElementById("companyroleLand").value == null || document.getElementById("companyroleLand").value == "") {
    labelAlertLand.style.display = "inline";
    return false;
  }
  else if (document.getElementById("privacyLand") == null || !document.getElementById("privacyLand").checked) {
    labelAlertLand.style.display = "inline";
    return false;
  }
  else if (document.getElementById("privacyLand2") == null || !document.getElementById("privacyLand2").checked) {
    labelAlertLand.style.display = "inline";
    return false;
  }

  labelAlertLand.style.display = "none";

  return true;
}

window.checkCampiLand = checkCampiLand;
//#endregion
*/

/*PROVA*/
document.addEventListener("DOMContentLoaded", function() {
  const formContatti = document.getElementById("formContatti");
  const confirmBtn = document.getElementById("confirmBtn");

  if (formContatti != null && confirmBtn != null) {
      confirmBtn.addEventListener("click", function(event) {
          console.log("funzione!!");

          event.preventDefault();
          grecaptcha.execute();
          if (checkCampi()) {
              console.log('formcontatti.submit');
              formContatti.submit();
          }
      });
  }

  const inputs = document.querySelectorAll("#formContatti input[type='text'], #formContatti textarea, #formContatti input[type='checkbox']");
  inputs.forEach(function(input) {
      input.addEventListener("change", function() {
          checkCampi();
      });
  });

  const formContattiLand = document.getElementById("formContattiLand");
  const confirmBtnLand = document.getElementById("confirmBtnLand");

  if (formContattiLand != null && confirmBtnLand != null) {
      confirmBtnLand.addEventListener("click", function() {
          console.log("funzione!!");
          if (checkCampiLand()) {
              formContattiLand.submit();
          }
      });
  }

  const inputsLand = document.querySelectorAll("#formContattiLand input[type='text'], #formContattiLand textarea, #formContattiLand input[type='checkbox']");
  inputsLand.forEach(function(input) {
      input.addEventListener("change", function() {
          checkCampiLand();
      });
  }); 
});

function checkCampi() {
  console.log("Inizio - checkcampi()");
  const labelAlert = document.getElementById("alert-message");
  const labelAlert2 = document.getElementById("alert-message2");
  const formFields = ["nome", "cognome", "email", "subject", "company", "testoLibero", "privacy", "privacy2"];
  let isValid = true;


  formFields.forEach(function(fieldId) {
      const fieldValue = document.getElementById(fieldId).value;
      console.log(fieldValue);
      if (fieldValue == null || fieldValue.trim() == "") {
          labelAlert.style.display = "inline";
          labelAlert2.style.display = "inline";
          isValid = false;
      }
  });

  if (isValid) {
      labelAlert.style.display = "none";
      labelAlert2.style.display = "none";
  }

  return isValid;
}

function checkCampiLand() {
  const labelAlertLand = document.getElementById("alert-message-land");
  const labelAlertLand2 = document.getElementById("alert-message-land2");
  const formFields = ["nomeLand", "cognomeLand", "emailLand", "phoneLand", "companyLand", "companyroleLand", "privacyLand", "privacyLand2"];
  let isValid = true;

  formFields.forEach(function(fieldId) {
      const fieldValue = document.getElementById(fieldId).value;
      if (fieldValue === null || fieldValue.trim() === "") {
          labelAlertLand.style.display = "inline";
          labelAlertLand2.style.display = "inline";
          isValid = false;
      }
  });

  if (isValid) {
      labelAlertLand.style.display = "none";
      labelAlertLand2.style.display = "none";
  }

  return isValid;
}
